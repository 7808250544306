import React from 'react';
import { Icon } from '../shared/Icon';
import '../../css/StirMenuTop.css';

export function StirMenuTop(props) {

  const getBottomContent = (hasError, name) => {
    return hasError ? (props.stir.hasClickedStir ? <Icon icon="caution" /> : name) : <Icon icon="check" />;
  };

  const getStep1Control = (isCurrentStep, hasError) => {
    const count = props.stir.getParticipantCount();
    const css = "fourths arrow-bar-left" + (isCurrentStep ? " current" : "");
    const topContent = count > 0 ? <>{count} <Icon icon="participant" /></> : <Icon icon="participants" />;
    return (
      <button className={css} onClick={() => props.handleStepChange(1)} onMouseDown={(e) => e.preventDefault()} disabled={isCurrentStep}>
        {topContent}
        <br />
        {getBottomContent(hasError, "PARTICIPANTS")}
      </button>);
  }

  const getStep2Control = (isCurrentStep, hasError) => {
    const count = props.stir.getTeamCount();
    const css = "fourths arrow-bar-middle" + (isCurrentStep ? " current" : "");
    const topContent = (count > 0) ? (<>{count} <Icon icon="team" /></>) : <Icon icon="theme" />;
    return (
      <button className={css} onClick={() => props.handleStepChange(2)} onMouseDown={(e) => e.preventDefault()} disabled={isCurrentStep}>
        {topContent}
        <br />
        {getBottomContent(hasError, "TEAMS")}
      </button>);
  }

  const getStep3Control = (isCurrentStep, hasError) => {
    const count = (props.stir.methodSteps?.length && props.stir.methodSteps[0].methodId > 0) ? props.stir.methodSteps.length : 0;
    const css = "fourths arrow-bar-middle" + (isCurrentStep ? " current" : "");
    const topContent = count > 0 ? <>{count} <Icon icon="stir" /></> : <Icon icon="stir" />;
    return (
      <button className={css} onClick={() => props.handleStepChange(3)} onMouseDown={(e) => e.preventDefault()} disabled={isCurrentStep}>
        {topContent}
        <br />
        {getBottomContent(hasError, "METHODS")}
      </button>);
  }

  const getStep4Control = (isCurrentStep, hasError) => {
    const css = "fourths arrow-bar-right" + (isCurrentStep ? " current" : "");
    const topContent = <Icon icon="cog" />;
    return (
      <button className={css} onClick={() => props.handleStepChange(4)} onMouseDown={(e) => e.preventDefault()} disabled={isCurrentStep}>
        {topContent}
        <br />
        {getBottomContent(hasError, "SETTINGS")}
      </button>);
  }

  const errors = props.stir.getValidationMessages();

  return (
    <div className="stir-body">
      <div className="stir-menu">
      <div>
        {getStep1Control(props.stir.stepNumber === 1, errors[1].length)}
        {getStep2Control(props.stir.stepNumber === 2, errors[2].length)}
        {getStep3Control(props.stir.stepNumber === 3, errors[3].length)}
        {getStep4Control(props.stir.stepNumber === 4, errors[4].length)}
        </div>
      </div>
    </div>
  );
}