import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../../css/ConfirmationModal.css';

export class ConfirmationModal extends Component {

  render() {
    const validationContent = (this.props.isInvalid && this.props.validationText)
      ? <span>{this.props.validationText}</span>
      : null;

    const buttonCss = (this.props.yesText?.length > 10 || this.props.noText?.length > 10) ? "btn-wide" : "";

    return (
      <>
        <Modal show={this.props.isOpen} onHide={this.props.onNo} animation={false} restoreFocus={false}>
          <Modal.Header closeButton={!this.props.hideHeaderCloseButton}>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.content}
          </Modal.Body>
          <Modal.Footer className={(this.props.isInvalid && this.props.validationText) ? "validation-text" : ""}>
            {validationContent}
            {this.props.noText ? <Button className={`secondary ${buttonCss}`} onClick={this.props.onNo}>{this.props.noText}</Button> : null}
            <Button className={buttonCss} disabled={this.props.isInvalid} onClick={this.props.onYes}>{this.props.yesText}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
