import React from 'react';
import { Icon } from '../shared/Icon';
import { UrlService } from '../../services/UrlService';

export function ReleaseNotes(props) {
  UrlService.useTitle('Release Notes');

    return (
      <div className="center">
        <div className="info-page-inner wide">
          <h1>Release Notes</h1>
          <div>
            <span className="bold">9/11/2024</span>
            <ul className="bullet-spoons">
              <li>
                Added the ability to link your Board Game Geek account with teamstir in your User Profile. This lets you add your board game collection to a stir with one click, and then vote with friends on which game to play.
              </li>
            </ul>
          </div>
          <div>
            <span className="bold">8/1/2024</span>
            <ul className="bullet-spoons">
              <li>
                Moved the 'Add Participants' step to the beginning of the stir creation workflow.
              </li>
            </ul>
          </div>
          <div>
            <span className="bold">7/28/2024</span>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Tie Breakers.</span> This new system allows you to set how teamstir will handle the common situation of when a team can be fairly assigned to more than one participant. For example, in a Rank Stir, two participants may have entered similar preferences, and you can configure teamstir to resolve this situation by assigning the preferred team to a participant randomly, by some priority order, or by using teamstir's karma system.
              </li>
            </ul>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Self Assign.</span> This new stirring method allows participants to select teams from a larger set of teams that have already been assigned to them.
              </li>
            </ul>
          </div>
          <div>
            <span className="bold">7/14/2024</span>
            <ul className="bullet-spoons">
              <li>Updated the stir creation UI for adding participants. You can now have multiple administrators, and those administrators can now be either active participants or passive observers.</li>
            </ul>
          </div>
          <div>
            <span className="bold">7/8/2024</span>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Admin Assign.</span> This new stirring method lets the stir administrator assign teams directly in a step. You could use this method at the beginning of a stir to capture activity that happened outside teamstir, or at the end of a stir to fill in teams for participants who never set preferences.
              </li>
            </ul>
          </div>
          <div>
            <span className="bold">2/4/2024</span>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Draft mode.</span> This new stirring method lets participants draft teams by claiming them one at a time, similar to the NFL (football) draft. Picks are drafted individually by each participant in sequence. The draft proceeds in 'snake' order, so that the last person in the first round of picks will be first in the second round, and so on with the draft order reversing each round.
              </li>
              <li>Changed the data structure for how teams are stored and related in our databasee.</li>
              <li>Fixed an issue where Secret Reveals were not displaying correctly.</li>
              <li>Added styling and other design updates.</li>
            </ul>
          </div>
          <div>
            <span className="bold">8/24/2022</span>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Login.</span> You can now log in to teamstir from the top nav using your private hash, which you can get from emails sent to you by teamstir. While logged in you can:
                <ul>
                  <li>Choose themes based on custom themes you've created before</li>
                  <li>Quickly select contacts from a list of participants you've previously stirred with</li>
                  <li>View your profile, including all stirs you have been a part of</li>
                  <li>Enjoy your personalized color theme</li>
                  <li>Clone an entire stir, filling in the theme, participants, steps, and event automatically</li>
                </ul>
              </li>
              <li>
                <span className="bold">New feature: Bulk participants.</span> You can now copy-paste participants en massse into the stir on step 2, similar to custom teams. This is useful when doing stirs for large groups.
              </li>
              <li>
                <span className="bold">New feature: Knockout voting.</span> You can now create stir steps that use the Knockout voting algorithm, which is similar to Instant Runoff Voting or Single Transferrable Vote. Participants rank all candidates in order, and the weakest candiates are sequentially eliminated, transferring their votes to other candidates, until only the specified number of candidates remain and are elected.
              </li>
              <li>
                <span className="bold">New feature: Delete custom themes.</span> You can now delete custom themes for your list of previous themes. While you are logged in, go to step 1 and select a custom theme. Click the trash icon to delete it from the list. The theme will still appear on resolved and in progress stirs.
              </li>
              <li>teamstir will now send a reminder email when there is only one participant remaining to set preferences, notifying them that the stir is waiting for them to respond.</li>
              <li>Setting preferences for Rank steps now has a smoother drag-and-drop interface. Hold the icon on a team to drag it, or click to select/unselect it.</li>
              <li>Added some new board game options to the list of predefined themes</li>
              <li>Fixed a bug where event times displayed in the UTC timezone instead of the user's local timezone.</li>
              <li>Fixed a bug where stir preferences did not save properly, or changed after reloading.</li>
            </ul>
          </div>
          <div>
            <span className="bold">6/25/2021</span>
            <ul className="bullet-spoons">
              <li><span className="bold">New feature: Secret Reveals.</span> Assigning teams can now be done in secret, so that participants only see their own assignments. This is a requested feature for keeping roles or other information secret like in Mafia, Resistance, or Secret Santa.</li>
            </ul>
          </div>
          <div>
            <span className="bold">6/22/2021</span>
            <ul className="bullet-spoons">
              <li>Added more information to the teamstir invitation and result emails, and fixed an issue where duplicate emails would be sent in some circumstances.</li>
              <li>Implemented a system for web browsers to avoid using stale cache for too long.</li>
              <li>Adjusted the 'commit' button so that it temporarily disables while processing team preference submission.</li>
            </ul>
          </div>
          <div>
            <span className="bold">6/15/2021</span>
            <ul className="bullet-spoons">
              <li>Fixed issues related to the stir message and stir observers.</li>
            </ul>
          </div>
          <div>
            <span className="bold">3/18/2021</span>
            <ul className="bullet-spoons">
              <li>Updated stir creation to smooth-add participants, so you can more easily tab between fields without hitting the 'add' button.</li>
              <li>Added a link to Wikipedia for every teamstir algorithm, so now you can read more about the details of how each of our algorithms works.</li>
              <li>Fixed a regression issue that impaired typing in the new participant input box.</li>
            </ul>
          </div>
          <div>
            <span className="bold">2/16/2021</span>
            <ul className="bullet-spoons">
              <li>As the concept behind teamstir has expanded, so have its entities and relationships. To keep up with how complex stirs can become with multi-step resolution, we restructured some of the code and database to handle many of the recent and upcoming features.</li>
            </ul>
          </div>
          <div>
            <span className="bold">2/14/2021</span>
            <ul className="bullet-spoons">
              <li>We've provisioned new databases for our production and test data by increasing their size by a factor of 1,000!</li>
            </ul>
          </div>
          <div>
            <span className="bold">2/1/2021</span>
            <ul className="bullet-spoons">
              <li><span className="bold">New feature: Stir Points.</span> Stir Points are displayed on the results page with the <Icon icon="star" css="active-color" /> icon next to teams that were ranked by Participants. Stir Points are calculated based on how highly a team is ranked by all the Participants. Technically speaking, Stir Points are a team's inverse ranking, so higher values on a team means it was more desired by the Participants.</li>
            </ul>
          </div>
          <div>
            <span className="bold">5/28/2020</span>
            <ul className="bullet-spoons">
              <li><span className="bold">New feature: Events.</span> You can now bundle your stirs together into events. An event is a collection of stirs, a time when it starts, and optionally an address and a freeform message to participants. Use this feature to group stirs that all relate to the same activity (such as a game of Twilight Imperium or dodgeball) for easy access. Participants can be the same or different across stirs in an event. Events are created during the new optional Step 4 of the stir creation wizard, so each event has at least one stir. Once an event is created, you can add additional stirs from the event details page, which is accessed from the context menu of any stir that is part of the event.</li>
            </ul>
          </div>
          <div>
            <span className="bold">5/4/2020</span>
            <ul className="bullet-spoons">
              <li><span className="bold">New feature: Color themes.</span> You can now pick one of three color themes for teamstir: Light (original), Dark, and Sunset. To change your theme, open the site using an email link, go to your profile page (person icon on the navbar), click the heading tile with your name on it, and choose "change colors".</li>
            </ul>
          </div>
          <div>
            <span className="bold">4/27/2020</span>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Group Method.</span> You can now set preferences for groups of teams. Unlike the Rank Method, which uses preference order, this method groups teams into categories, each of which has a different preference. Currently, this method supports two groups, which means participants divide teams into preferred and unpreferred categories. The Hungarian and Cascade algorithms are supported for assigning matches, and the new Approval Vote algorithm is supported for electing or excluding teams.
              </li>
              <li>
                <span className="bold">New feature: Approval Voting.</span>  This algorithm elects or excludes teams based on individual votes for or against them. Participants may vote for (approve) as many teams as they want, and the winners are the teams with the highest total number of votes, with ties broken at random. All votes have the same weight, so their order is not relevant. Examples:
                <ul>
                  <li>Pick the best house rules for your favorite game</li>
                  <li>Choose a date that works for the most party guests</li>
                  <li>Find which games your friends like to play most</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <span className="bold">4/10/2020</span>
            <ul className="bullet-spoons">
              <li>Bugfix: Invitation emails were not being sent</li>
              <li>Bugfix: Results emails for Elect and Exclude steps were not being sent</li>
              <li>Bugfix: Stirs where the steps had no deadline would resolve without the actual steps resolving</li>
            </ul>
          </div>
          <div>
            <span className="bold">4/9/2020</span>
            <ul className="bullet-spoons">
              <li>
                <span className="bold">New feature: Elections.</span> You can now create stirs that elect outcomes instead of creating matches. By defining custom teams, you can allow users to vote on any topic, and specify how many outcomes will be chosen. Examples:
                <ul>
                  <li>Choose the most desirable day to hold the next event</li>
                  <li>Vote on rules changes</li>
                  <li>Vote on which version of a game to play</li>
                  <li>Vote for three participants to be group leaders</li>
                  <li>Select a short list of ten interview candidates to invite for followup interviews</li>
                </ul>
                The initially supported voting system is the <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Borda_count">Borda Count</a>. Each outcome chosen by a particpant receives points equal to the number of choices ranked below it. The outcomes with the most total points are elected, with ties broken at random. Use a Borda Count when you want to choose a fixed number of the most popular outcomes. Note that participants who vote for more options will affect the results more heavily.
              </li>
              <li>
                <span className="bold">New feature: Exclusions.</span> You can now create stirs that exclude/remove outcomes instead of creating matches. These types of stirs are usually a prelude to further decision making. Examples:
                <ul>
                  <li>Ban overpowered teams or characters from a game</li>
                  <li>Remove from consideration any alternatives that are dealbreakers for some participants</li>
                  <li>Exclude the weakest ten interview candidates from continuing the hiring process</li>
                </ul>
              </li>
              <li>
                <span className="bold">New feature: Multistep.</span> You can now create stirs that have multiple steps. Each step has separate settings for Purpose (Assign/Match, Elect, Exclude), Method, and so on. The results from each step will be fed into the next step. If you exclude teams in one step, they will be unavailable to elect or assign in later steps. If you elect teams in one step, they will be the only teams that are available for use in later steps. Examples:
                <ul>
                  <li>Ban overpowered factions in a game (step 1 excludes teams) and then have players choose their teams from the remaining options (step 2 assigns teams)</li>
                  <li>Allow senior students to pick research topics first (step 1 assigns) and then let junior students choose from the remaining topics (step 2 assigns)</li>
                  <li>Reject interview candidates where any committee member has a negative impression (step 1 excludes teams) and then vote on the best single candidate to hire (step 2 elects teams)</li>
                </ul>
                Note that the outcomes chosen (teams) must be from the same initial list for each step. It is not currently possible to choose from two separate lists of things, such as picking a date and then picking a group leader.
              </li>
              <li>Many UI adjustments in Stir Creation to support Elect, Exclude, and Multistep features -- denser styling, additional fields, language changes</li>
              <li>Added a visual description with icons to preview how many participants, teams, and matches will go into a step and how many will be output at the end.</li>
              <li>Bugfix: When removing a participant caused a stir to become complete (because all other participants had already submitted), the results email did not get sent.</li>
              <li>The Stir Results page will now show all participants and teams that took part, along with various icons to show which ones ended up assigned, unassigned, elected, and excluded</li>
            </ul>
          </div>
          <div>
            <span className="bold">3/17/2020</span>
            <ul className="bullet-spoons">
              <li>Matches section added to stir creation, which shows the estimated number of matches based on user settings</li>
            </ul>
          </div>
          <div>
            <span className="bold">3/11/2020</span>
            <ul className="bullet-spoons">
              <li>Assign Me Last button added to rank preference page. Check the box to let other players pick first in case of conflict</li>
              <li>Custom themes no longer show in the theme list to avoid clutter while we expand custom theme support</li>
              <li>Release Notes page added</li>
            </ul>
          </div>
        </div>
      </div>
    );
}
