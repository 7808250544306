import Validator from './ValidationService';
import Constants from './Constants';
import { useEffect } from 'react';

export function UrlService(props) {
}

UrlService.getPathFromUrl = () => {
  return window.location.pathname.substring(1);
}

UrlService.getCurrentUrl = () => {
  return window.location.pathname + window.location.search;
}

UrlService.getUrlParams = () => {
  return window.location.search.length ? window.location.search.substring(1).split("&") : [];
}

UrlService.getUserHashFromUrl = () => {
  const params = UrlService.getUrlParams().map(x => x.split("=").length === 2 ? x.split("=")[1] : "");
  params.push(UrlService.getPathFromUrl());
  return params.find(x => Validator.isHashValid(x, Constants.hash.user)) || "";
}

UrlService.getUrl = (url, urlSuffix) => {
  const userHash = UrlService.getUserHashFromUrl();
  const params = [
    (userHash && userHash.length && url !== `/${userHash}` ? "user=" + userHash : ""),
    (urlSuffix || "")
  ].filter(x => x && x.length);
  return url + (params.length ? ("?" + params.join("&")) : "");
}

UrlService.getHashFromUrl = () => {
  const hash = UrlService.getPathFromUrl().replace("administer/", "").replace("pick/", "").replace("assign/", "");
  return Validator.isHashValid(hash) ? hash : null;
}

UrlService.getAlternateEnvironment = (url) => {
  var result = {
    DisplayText: "{} VERSION",
    Url: null
  };

  var host = window.location.host;

  if (host.toLowerCase().includes("beta")) {
    result.DisplayText = result.DisplayText.replace('{}', 'STABLE');
    result.Url = "https://teamstir.io";
  } else {
    result.DisplayText = result.DisplayText.replace('{}', 'BETA');
    result.Url = "https://beta.teamstir.io";
  }

  return result;
}

UrlService.getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

UrlService.getStirUrlParameters = () => {
  const path = window.location.pathname.split("/").filter(x => x);

  const stepNumber = (path.length === 2 && path[0] === "stir" && (parseInt(path[1])) <= Constants.maxStepNumber + 1) ? parseInt(path[1]) : 1;
  const templateStirHash = UrlService.getParameterByName("templateStir");
  const templateEventHash = UrlService.getParameterByName("templateEvent");
  const firstStepMethod = UrlService.getParameterByName("method");

  return {
    'stepNumber': stepNumber,
    'templateStirHash': templateStirHash,
    'templateEventHash': templateEventHash,
    'firstStepMethod': firstStepMethod
  };
};

UrlService.getUrlParams = () => {
  return window.location.search.length ? window.location.search.substring(1).split("&") : [];
}

UrlService.getUrlParam = (paramName) => {
  const param = UrlService.getUrlParams()
    .map(x => x.split("=").length === 2 ? x.split("=") : [])
    .filter(x => x && x[0] === paramName)
    .map(x => (x && x.length) ? x[1] : null);
  return (param && param.length) ? param[0] : null;
}

UrlService.useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = "teamstir - " + title;

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}

UrlService.isStirring = () => {
  return window.location.pathname.includes("/stir");
}
